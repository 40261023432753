//------------------------------------------------
// viewport
//------------------------------------------------
var baseW = 741;	// 基準となるブレイクポイント（PC）
var iOSviewportW = 0;
var ua = navigator.userAgent.toLowerCase();
var isiOS = (ua.indexOf("iphone") > -1) || (ua.indexOf("ipod") > -1) || (ua.indexOf("ipad") > -1);
if(isiOS){
	iOSviewportW = document.documentElement.clientWidth;
}
function updateMetaViewport(){
	var viewportContent;
	var w = window.outerWidth;
	if(isiOS){
		w = iOSviewportW;
	}
	if(w < baseW) {
		viewportContent = "width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no";
  } else if(w >= 768 && w <=1024) {
    viewportContent = "width=1024";
	} else {
		viewportContent = "width=device-width, initial-scale=1, shrink-to-fit=no";
	}
	document.querySelector("meta[name='viewport']").setAttribute("content", viewportContent);
}

window.addEventListener("resize", updateMetaViewport, false);
window.addEventListener("orientationchange", updateMetaViewport, false);

var ev = document.createEvent("UIEvent");
ev.initEvent("resize", true, true)
window.dispatchEvent(ev);

//------------------------------------------------
// imgDeviceChange
//------------------------------------------------
$(window).on('load resize', imgDeviceChange)
function imgDeviceChange() {
  var mediaquery = 740;
  var className = '.js-img-deviceChange'
  if (window.innerWidth > mediaquery) {
    $(className).each(function () {
      $(this).attr('src', $(this).attr('src').replace('_sp', '_pc'))
    })
  } else {
    $(className).each(function () {
      $(this).attr('src', $(this).attr('src').replace('_pc', '_sp'))
    })
  }
}

//------------------------------------------------
// accordion
//------------------------------------------------
$(".js-accordion-open").on('click', function () {
  $(this).next().stop().slideToggle();
  $(this).toggleClass("newsList__title--open");
});

//------------------------------------------------
// moreBtn
//------------------------------------------------
var triggerClass = ".js-moreBtn";
var currentWidth = window.innerWidth;

firstView();
$(window).on('resize', function() {
  var mediaquery = 740;
  if (currentWidth === window.innerWidth) {
    return
  } else {
    if(window.innerWidth <= mediaquery) {
      firstView();
    } else {
      $(triggerClass).each(function () {
        var getData_hide = $(this).attr('data-target_hide');
        $(getData_hide + '.js-is-hidden').fadeIn(100);
        $(getData_hide + '.js-is-hidden').removeClass('js-is-hidden');
      });
    }
  }
  currentWidth = window.innerWidth;
});

$(triggerClass).on('click', function () {
  var getData_hide = $(this).attr('data-target_hide');
  if($(getData_hide).hasClass('js-is-hidden')) {
    $(this).text("閉じる");
    $(this).addClass('collapse-open');
    $(getData_hide + '.js-is-hidden').fadeIn('slow');
    $(getData_hide + '.js-is-hidden').removeClass('js-is-hidden');
  } else {
    firstView();
    var getData_targetScroll = $(this).attr('data-target_scroll');
    var position = $(getData_targetScroll).offset().top;
    var speed = 1000;
    $("html,body").animate({scrollTop:position},speed);
  }
});

function firstView() {
  $(triggerClass).each(function () {
    var getData_firstShow = Number($(this).attr('data-first_show'));
    var getData_hide = $(this).attr('data-target_hide');
    $(getData_hide+':nth-child(n+'+(getData_firstShow+1)+')').addClass('js-is-hidden');
    $('.js-is-hidden').fadeOut('slow');
  })
  $(triggerClass).text("もっと見る");
  $(triggerClass).removeClass('collapse-open');
};

//------------------------------------------------
// datepicker
//------------------------------------------------
$(function() {
  const isPcDevice = window.innerWidth > 768 ? true : false
  let holidays = null
  $.get("https://holidays-jp.github.io/api/v1/date.json", function(holidaysData) {
    holidays = Object.keys(holidaysData)
  })

  const dateToday = new Date()
  const dates = $("#js-datepicker").datepicker({
    dateFormat: 'yy/mm/dd',
    minDate: dateToday,
    changeMonth: true,
    numberOfMonths: isPcDevice ? 2 : 1,
    duration: 300,
    showButtonPanel: true,
    beforeShowDay: function(date) {
      const dateDay = date.getDay()
      for(let i=0; i < holidays.length; i++) {
        const holiday = new Date(Date.parse(holidays[i]))
        const isSameYear = holiday.getYear() === date.getYear()
        const isSameMonth = holiday.getMonth() === date.getMonth()
        const isSameDate = holiday.getDate() === date.getDate()
        if(isSameYear && isSameMonth && isSameDate) {
          return [true, 'day-holiday', null]
        }
      }
      if(dateDay === 0) {
        return [true, 'day-sunday', null]
      } else if(dateDay === 6) {
        return [true, 'day-saturday', null]
      } else {
        return [true, 'day-weekday', null]
      }
    },
    onSelect: function(selectedDate) {
      const $_this = $(this)
      const option = $_this.id == "from" ? "minDate" : "maxDate"
      const instance = $_this.data("datepicker")
      const date = $.datepicker.parseDate(instance.settings.dateFormat || $.datepicker._defaults.dateFormat, selectedDate, instance.settings)
      dates.not($_this).datepicker("option", option, date)
      $_this.attr('name', '')
    },
    beforeShow: function(input) {
      setTimeout(function() {
        let dateObj = {
          thisMonth: [null, null],
          nextMonth: [null, null]
        }
        const dateThisMonthElement = $('.ui-datepicker-month[data-handler="selectMonth"]')
        dateObj.thisMonth[0] = dateThisMonthElement.prev().text()
        dateObj.thisMonth[1] = dateThisMonthElement.find('option:selected').text().replace(/月/g, '')
        if(isPcDevice) {
          const dateNextMonthElement = $('span.ui-datepicker-month')
          dateObj.nextMonth[0] = dateNextMonthElement.prev().text()
          dateObj.nextMonth[1] = dateNextMonthElement.text().replace(/月/g, '')
        }

        const buttonPane = $(input).datepicker('widget').find('.ui-datepicker-buttonpane')
        const dateAry = Object.keys(dateObj)
        for(let i=0; i < dateAry.length; i++) {
          const key = dateAry[i]
          const dateYear = dateObj[key][0]
          const dateMonth = dateObj[key][1]
          if(dateYear !== null) {
            let month = ('0'+ new Date(dateYear, dateMonth).getMonth()).slice(-2)
            month = month == '00' ? 12 : month
            const setDateVal = dateMonth +'月未定';
            $('<button>', {
              text: dateMonth +'月未定',
              click: function() {
                const datepickerElement = $('#js-datepicker')
                datepickerElement.val(setDateVal)
                datepickerElement.attr('name', 'departureMonth')
                datepickerElement.datepicker('hide')
                datepickerElement.blur()
              }
            }).appendTo(buttonPane).addClass('ui-datepicker-close is-block ui-state-default ui-priority-primary ui-corner-all')
          }
        }
      }, 1)
    },
    onChangeMonthYear: function(instance) {
      setTimeout(function() {
        let dateObj = {
          thisMonth: [null, null],
          nextMonth: [null, null]
        }
        const dateThisMonthElement = $('.ui-datepicker-month[data-handler="selectMonth"]')
        dateObj.thisMonth[0] = dateThisMonthElement.prev().text()
        dateObj.thisMonth[1] = dateThisMonthElement.find('option:selected').text().replace(/月/g, '')
        if(isPcDevice) {
          const dateNextMonthElement = $('span.ui-datepicker-month')
          dateObj.nextMonth[0] = dateNextMonthElement.prev().text()
          dateObj.nextMonth[1] = dateNextMonthElement.text().replace(/月/g, '')
        }

        const buttonPane = $(instance).datepicker('widget').find('.ui-datepicker-buttonpane')
        const dateAry = Object.keys(dateObj)
        for(let i=0; i < dateAry.length; i++) {
          const key = dateAry[i]
          const dateYear = dateObj[key][0]
          const dateMonth = dateObj[key][1]
          if(dateYear !== null) {
            let month = ('0'+ new Date(dateYear, dateMonth).getMonth()).slice(-2)
            month = month == '00' ? 12 : month
            const setDateVal = dateMonth +'月未定';
            $('<button>', {
              text: dateMonth +'月未定',
              click: function() {
                const datepickerElement = $('#js-datepicker')
                datepickerElement.val(setDateVal)
                datepickerElement.attr('name', 'departureMonth')
                datepickerElement.datepicker('hide')
                datepickerElement.blur()
              }
            }).appendTo(buttonPane).addClass('ui-datepicker-close is-block ui-state-default ui-priority-primary ui-corner-all')
          }
        }
      }, 1)
    }
  })
})

//------------------------------------------------
// search
//------------------------------------------------
$(".js-accordion-open02").on('click', function () {
  $(this).next().stop().slideToggle();
  $(this).toggleClass("search-detail__title--open");
  $(this).parent().toggleClass("search-detail--open");
});

//------------------------------------------------
// slide
//------------------------------------------------
$(function () {
	var $mvSlideEl = $('#mv');
  var $slideItems = $('.mvSlide__item').length;
  var slideDots = true;
  if($slideItems <= 1) {
    slideDots = false;
    $('#mv__prevButton').hide();
    $('#mv__nextButton').hide();
  }
	$mvSlideEl.slick({
		arrows: false,
		fade: true,
		dots: slideDots,
		lazyLoad: 'ondemand',
    autoplay: true,
    autoplaySpeed: 5000
	});
	$('#mv__prevButton').on('click', function () {
		$mvSlideEl.slick('slickPrev');
	});
	$('#mv__nextButton').on('click', function () {
		$mvSlideEl.slick('slickNext');
	});

	var $slick01 = $('.js-slide-ranking');
	$slick01.slick({
		arrows: false,
		centerMode: true,
		centerPadding: '18px',
		adaptiveHeight: false
	});
});

//------------------------------------------------
// searchRavelAccordion
//------------------------------------------------
$(".search-label .js-search-accordion-open").on('click', function () {
  var accordionArea = $(".search-label__contents");
  $(this).next(accordionArea).slideDown(200);
});

$(document).on('click', function (area) {
  var searchRavelArea = $(".search-flexBox").children(".search-label");
  var accordionArea = $(".search-label__contents");
  if (!$(area.target).closest(searchRavelArea).length) {
    $(accordionArea).slideUp(200);
  } else {
    var remainArea = $(area.target).closest(searchRavelArea).find(accordionArea);
    $(accordionArea).not(remainArea).slideUp(200);
  }
});

$(".search-label__contents .js-search-label-check").on('change', function () {
  var accordionArea = $(".search-label__contents");
  var checkedItem = $(this).closest(accordionArea).find("input[type='checkbox']:checked");
  var inputText = $(this).closest(accordionArea).prev("input[type='text']");

  var checkedValues = [];
  checkedItem.each(function() {
    var targetVal = $(this).next("label").text();
    checkedValues.push(targetVal);
  });
  if(checkedValues.length === 0) {
    inputText.prop("value", "指定なし");
  } else if(checkedValues.length === 1) {
    inputText.prop("value", checkedValues[0]);
  } else {
    inputText.prop("value", "複数選択");
  }
});

//------------------------------------------------
// changeBudget
//------------------------------------------------
$(".js-change-budget").on('change', function() {
  const $_this = $(this);
  const selectedVal = parseInt($_this.val());
  let budetObj = {
    changedBudetNameAttr: null,
    notChangedBudetNameAttr: null
  }
  budetObj.changedBudetNameAttr = $_this.attr('name');
  budetObj.notChangedBudetNameAttr = budetObj.changedBudetNameAttr === 'lowerBudget' ? 'upperBudget' : 'lowerBudget';

  const notChangedBudetSelect = $('[name="'+ budetObj.notChangedBudetNameAttr +'"]');
  const notChangedBudetSelectOptions = notChangedBudetSelect.find('option');
  const notChangedBudetVal = parseInt(notChangedBudetSelect.val());
  const changedBudetSelectOptions = $('[name="'+ budetObj.changedBudetNameAttr +'"] option');
  if(budetObj.notChangedBudetNameAttr === 'upperBudget') {
    notChangedBudetSelectOptions.each(function() {
      const $_this = $(this);
      if(parseInt($_this.val()) < selectedVal) {
        $_this.prop('disabled', true);
      } else {
        $_this.prop('disabled', false);
      }
    });

    changedBudetSelectOptions.each(function() {
      const $_this = $(this);
      if(parseInt($_this.val()) > notChangedBudetVal) {
        $_this.prop('disabled', true);
      } else {
        $_this.prop('disabled', false);
      }
    });
  } else {
    notChangedBudetSelectOptions.each(function() {
      const $_this = $(this);
      if(parseInt($_this.val()) > selectedVal) {
        $_this.prop('disabled', true);
      } else {
        $_this.prop('disabled', false);
      }
    });

    changedBudetSelectOptions.each(function() {
      const $_this = $(this);
      if(parseInt($_this.val()) < notChangedBudetVal) {
        $_this.prop('disabled', true);
      } else {
        $_this.prop('disabled', false);
      }
    });
  }
});

//------------------------------------------------
// smoothScroll
//------------------------------------------------

$(function () {
  $('a[href^="#"]').click(function () {
    var speed = 400;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({
      scrollTop: position
    }, speed, "swing");
    return false;
  });
});